import type { AnyFn } from '~/ts/types/common'

export type UseScroll = {
    isScrolling: Ref<boolean>
    arrivedTop: Ref<boolean>
    arrivedLeft: Ref<boolean>
    arrivedBottom: Ref<boolean>
    arrivedRight: Ref<boolean>
    top: Ref<number>
    left: Ref<number>
    touch: VoidFunction
}

export type UseScrollOptions = {
    debounce?: number
    onScrollStart?: AnyFn
    onScrollEnd?: AnyFn
    onScrollArrivedTop?: AnyFn
    onScrollArrivedLeft?: AnyFn
    onScrollArrivedBottom?: AnyFn
    onScrollArrivedRight?: AnyFn
}

export const useScroll = (elRef: Ref<HTMLElement>, options: UseScrollOptions = {}): UseScroll => {
    const isScrolling = ref<boolean>(false)
    const arrivedTop = ref<boolean>(true)
    const arrivedLeft = ref<boolean>(true)
    const arrivedBottom = ref<boolean>(true)
    const arrivedRight = ref<boolean>(true)
    const top = ref<number>(0)
    const left = ref<number>(0)

    const { debounce = 500 } = options

    const getArrivedTop = (): boolean => {
        return !elRef.value.scrollTop
    }

    const getArrivedLeft = (): boolean => {
        return !elRef.value.scrollLeft
    }

    const getArrivedBottom = (): boolean => {
        const { scrollTop, scrollHeight, clientHeight } = elRef.value

        return Math.round(scrollTop + clientHeight) >= scrollHeight
    }

    const getArrivedRight = (): boolean => {
        const { scrollLeft, scrollWidth, clientWidth } = elRef.value

        return Math.round(scrollLeft + clientWidth) >= scrollWidth
    }

    const handleScrollStart = (): void => {
        isScrolling.value = true

        options?.onScrollStart?.()
    }

    const handleScrollEnd = (): void => {
        isScrolling.value = false

        options?.onScrollEnd?.()
    }

    const handleScrollY = (): void => {
        if (getArrivedTop()) {
            if (!arrivedTop.value) {
                arrivedTop.value = true

                options?.onScrollArrivedTop?.()
            }
        } else {
            arrivedTop.value = false
        }

        if (getArrivedBottom()) {
            if (!arrivedBottom.value) {
                arrivedBottom.value = true

                options?.onScrollArrivedBottom?.()
            }
        } else {
            arrivedBottom.value = false
        }
    }

    const handleScrollX = (): void => {
        if (getArrivedLeft()) {
            if (!arrivedLeft.value) {
                arrivedLeft.value = true

                options?.onScrollArrivedLeft?.()
            }
        } else {
            arrivedLeft.value = false
        }

        if (getArrivedRight()) {
            if (!arrivedRight.value) {
                arrivedRight.value = true

                options?.onScrollArrivedRight?.()
            }
        } else {
            arrivedRight.value = false
        }
    }

    const scrollEventHandler = {
        started: false,
        timer: undefined,
        handler() {
            if (!elRef.value) {
                return
            }

            const self = scrollEventHandler

            top.value = elRef.value.scrollTop
            left.value = elRef.value.scrollLeft

            if (!self.started) {
                handleScrollStart()
            }

            handleScrollY()
            handleScrollX()

            self.started = true

            clearTimeout(self.timer)

            self.timer = setTimeout(() => {
                handleScrollEnd()

                self.started = false
                self.timer = undefined
            }, debounce)
        },
    }

    let stopListener = noop

    const stopWatch = watch(
        elRef,
        (el) => {
            if (!el) {
                stopListener()
                stopListener = noop

                return
            }

            arrivedTop.value = getArrivedTop()
            arrivedLeft.value = getArrivedLeft()
            arrivedBottom.value = getArrivedBottom()
            arrivedRight.value = getArrivedRight()
            top.value = elRef.value.scrollTop
            left.value = elRef.value.scrollLeft

            stopListener = onEvent(elRef, 'scroll', scrollEventHandler.handler, { passive: true })
        },
        {
            immediate: true,
            flush: 'post',
        },
    )

    const stop = () => {
        stopWatch()
        stopListener()
        stopListener = noop
    }

    if (getCurrentScope()) {
        onScopeDispose(stop)
    }

    const restoreScrollPosition = () => {
        if (!elRef.value) {
            return
        }

        if (top.value) {
            elRef.value.scrollTop = top.value
        }

        if (left.value) {
            elRef.value.scrollLeft = left.value
        }
    }

    onActivated(restoreScrollPosition)

    return {
        isScrolling,
        arrivedTop,
        arrivedLeft,
        arrivedBottom,
        arrivedRight,
        top,
        left,
        touch: scrollEventHandler.handler,
    }
}
